<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Types" subtitle="Manage the event types that can be specified when scheduling events." :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no types found.">

		<app-content-note name="schedule.types.creating">
		
			Having different event types allows attendees to more easily filter and search for things of interest. You can also restrict rooms to only be used for specific types of events.
		
		</app-content-note>

		<app-content-note theme="red" name="schedule.types.default" v-if="!hasDefault && collection.length">
		
			We recommend you have a default type that most attendees will likely be using to streamline their event submission experience and help reduce wrong selections.
		
		</app-content-note>

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Name" />
				<app-table-column align="center" text="Default" />
				<app-table-column align="center" text="Public" />
				<app-table-column align="center" text="Games" />
				<app-table-column align="center" text="Badges" border="left" />
				<app-table-column align="center" text="Events" />
				<app-table-column align="center" text="Rooms" border="right" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.name" />
					<app-table-cell-icon align="center" :theme="item.default ? 'green' : 'red'" :icon="item.default ? 'tick' : 'cross'" />
					<app-table-cell-icon align="center" :theme="item.public ? 'green' : 'red'" :icon="item.public ? 'tick' : 'cross'" />
					<app-table-cell-icon align="center" :theme="item.games.enabled ? 'green' : 'red'" :icon="item.games.enabled ? 'tick' : 'cross'" />
					<app-table-cell-text align="center" :text="(item.public) ? '-' : item.count.badges" border="left" />
					<app-table-cell-text align="center" :text="item.count.events" />
					<app-table-cell-text align="center" :text="item.count.rooms" border="right" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: 'auto 80px 80px 80px 80px 80px 80px 24px',
			endpoint: 'convention/schedule/types',
			live: 'convention/schedule/types'
		}

	},

	computed: {

		hasDefault: function() {

			return this.$_.where(this.collection, {
				default: 1
			}).length

		}

	}

}

</script>

<style scoped>

</style>